// const webApi = 'https://dev.diansj.com/';
// const shengc = 'https://www.diansj.com/';
// const fileUrl = webApi + '/pc-prod-api/common/files';
// const serviceUrl = webApi + 'pc-prod-api';
// const shengcUrl = shengc + 'pc-prod-api';
// export default {
// 	webApi,
// 	shengc,
// 	fileUrl,
// 	serviceUrl,
// 	shengcUrl
// };

// 刘礼畅接口
const LLCURL = {
	baseUrl: 'http://192.168.31.60:8086', // 请求地址
	fileUrl: 'https://dev.diansj.com/' // 文件获取地址
};
// 王安心接口
const WAXURL = {
	baseUrl: 'http://192.168.31.6:8086', // 请求地址
	fileUrl: 'https://dev.diansj.com/' // 文件获取地址
};
// 开发环境接口
const SIT = {
	baseUrl: 'https://dev.diansj.com/pc-prod-api', // 请求地址
	fileUrl: 'https://dev.diansj.com/' // 文件获取地址
};
// 线上正式接口
const PROD = {
	baseUrl: 'https://www.diansj.com/pc-prod-api', // 请求地址
	fileUrl: 'https://www.diansj.com/' // 文件获取地址
};
//  		   ↓
//  		   ↓
//  		   ↓
let address = SIT; // ← ← ← ← ← ← ← ← ← 修改此处的配置即可
//  		   ↑
//  		   ↑
// 			   ↑
export default {
	baseUrl: address.baseUrl, // 请求地址
	fileUrl: address.fileUrl // 文件获取地址
};
